<template>
  <van-popup
    v-model:show="show"
    round
    position="bottom"
    teleport="body"
    close-on-popstate
    safe-area-inset-bottom
    :duration="0.25"
    @click-overlay="closeHandler"
  >
    <div class="van-safe-area-bottom" flex="~ col" :style="{ maxHeight: '90vh', height }">
      <div flex="~ items-center justify-center" py="4">
        <div m="4" h="6" w="6" rounded="full" bg="#EDF0EF" class="absolute left-0 top-0" flex="~ justify-center items-center" @click="closeHandler">
          <NuxtIcon name="my-icon:navback" class="text-base text-#121212 -rotate-90" />
        </div>
        <div text="base #121212">
          {{ title }}
        </div>
      </div>
      <div px="4" pb="2" flex="1" overflow-y="auto">
        <slot></slot>
      </div>
      <slot name="footer">
      </slot>
    </div>
  </van-popup>
</template>

<script lang="ts" setup>
defineProps<{
  title?: string
  height?: string
}>()

const emit = defineEmits<{
  (e: 'userClose'): void
}>()

const show = defineModel('show', {
  type: Boolean,
  default: false,
})

function closeHandler() {
  show.value = false
  emit('userClose')
}
</script>
